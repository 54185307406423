
































































































import UploadFileComponent from '@/components/staffManagement/components/uploadfile/UploadFileComponent';
export default UploadFileComponent;
