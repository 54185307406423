

























































































import StaffMatchColumnsComponent from '@/components/staffManagement/components/matchcolumns/StaffMatchColumnsComponent';
export default StaffMatchColumnsComponent;
