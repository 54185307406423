import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import DropdownComponent from '@/ui-components/dropdown/DropdownComponent.vue';
import staff from '@/store/modules/staffManager';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import UIkit from 'uikit';
import UnresolvedAlertComponent from '@/popupcomponents/unresolvedalertcomponent/UnresolvedAlertComponent.vue';
import UploadProgress from '@/popupcomponents/uploadprogress/UploadProgress.vue';

@Component({
  components: {
    'drop-down': DropdownComponent,
    'unresolved-popup': UnresolvedAlertComponent,
    'upload-progress': UploadProgress
  }
})
export default class StaffMatchColumnsComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    selectedCsvLabel: number | null = null;
    selectedBxGroup: number | null = null;
    stickyWrapperBtn: boolean = false;
    getGlobalState = getModule(GlobalModule, store);
    showNotice: boolean = false;
    openPopup: boolean = false;
    progressOpen:boolean = false;
    mappedData:any = [];
    unmappedCsvHeaders:any = [];
    mandatoryHeaders:any = [];
    csvHeaders:any = [];
    bxHeaders:any = [];
    unresolvedRowIndexes:any = [];
    currentUnresolved:number = 0;
    maxCustReached:boolean = false;
    validity:boolean = true;
    dataFetched:boolean = false;
    currentPage:string = 'staff-roster';
    public disableContinueButton: boolean = false;

    @Prop()
    userDetails!: any;

    @Prop()
    stepId!: number;

    @Emit('next') nextScreen(mode: string) { }

    created() {
      this.obtainReqHeaders();
      window.addEventListener('scroll', this.handleScroll);
    }   

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }


    get groupWiseHeaders() {        
      return this.getGlobalState.GroupedHeaders;
    }

    goToRepairProb() {
      this.saveAutoRoaster();
      this.disableContinueButton = true;
      this.validity=true;
      /* istanbul ignore else */
      if(this.unresolvedRowIndexes.length>0) {
        this.openPopup = true;
        this.mappedData.forEach((item:any) => {
          if(this.unresolvedRowIndexes.indexOf(item[0].rowIndex)!==-1 && !item.isCustom){
            if(this.mandatoryHeaders.indexOf(item[0].columnName)!==-1){
              this.validity = false;
            }
          }
        });
        setTimeout(() => {
          if (UIkit.modal('#unresoved-popup')) {
            UIkit.modal('#unresoved-popup').show();
            this.disableContinueButton = false;
          }
        },1000);
      }
      else {
        this.validity=true;
        this.progressOpen =true;
        this.moveNext();
      }
    }

    close() {
      this.openPopup = false;
      this.progressOpen = false;
      setTimeout(() => {
        if (UIkit.modal('#unresoved-popup')) {
          const element:any = document.querySelectorAll('#unresoved-popup');
          UIkit.modal('#unresoved-popup').hide();
          element[1]&& element[1].remove();
          element[2]&& element[2].remove();
        }
      },1000);
    }

    moveNext() {
      this.openPopup = false;
      this.progressOpen = false;
      const {siteId, programId} = APP_UTILITIES.coreids();
      this.openPopup = false;
      this.progressOpen = false;
      const configureObj: {
        'programId': number;
        'siteId': number;
        'rosterStep': number;
        'step1': boolean;
        'step2': string;
        'step3': string;
        'visibleFileName': string;
        'actualFileName': string;
        'status': number;
        } = {
          'programId': programId,
          'siteId': siteId,
          'rosterStep': 3,
          'step1': true,
          'step2': '',
          'step3': '',
          'visibleFileName': '',
          'actualFileName': '',
          'status': 1
        };
      const step2ArrayFill:any = [];

      this.mappedData.forEach((item:any) => {
        const obj:any ={
          bxLabel:item[0].columnName,
          csvLabel: item[1].columnName,
          isCustom: item[0].isCustom
            ? true
            :false,
          isManuallyMapped: item[1].isManuallyMapped
            ?true
            :false,
          rowIndex: item[0].rowIndex,
          csvRowIndex:item[1].rowIndex
            ?item[1].rowIndex
            :''
        };
        step2ArrayFill.push(obj);          
      });
       
      configureObj.step2 = JSON.stringify(step2ArrayFill);
      this.getGlobalState.autoSaveStep2Data(configureObj.step2);
      setTimeout(() => {

        staff.autoSaveStaffRoster(configureObj).then((response: any) => {
        });
        if (UIkit.modal('#unresoved-popup')) {
          const element:any = document.querySelectorAll('#unresoved-popup');
          UIkit.modal('#unresoved-popup').hide();
          element[1]&& element[1].remove();
          element[2]&& element[2].remove();
        }
        this.nextScreen('repair-table');
        this.disableContinueButton = false;
      }, 1200);
    }

    backToUpload() {
      this.openPopup = false;
      this.nextScreen('upload-file');
    }

    obtainHeaders(mandatoryHeaders: any) {
      const {siteId, programId} = APP_UTILITIES.coreids();
      const accountId:any = APP_UTILITIES.getCookie('accountId');
      staff.getAllStaffHeaders(accountId).then((headersRes: any) => {
        if(headersRes && headersRes.data && headersRes.data.name && headersRes.data.name=='Error_CsvHeaderColumns'){
          this.validity = false;
        }
        /* istanbul ignore else */
        if (headersRes.status === APP_CONST.RESPONSE_200) {
              
          const bxHeaders:any = headersRes.data.bxHeaders;
          const csvHeaders:any = headersRes.data.csvHeaders;
          this.csvHeaders = JSON.parse(JSON.stringify(csvHeaders));
          this.bxHeaders = bxHeaders;
          this.mandatoryHeaders = mandatoryHeaders;
          const prepareMappedData:any = [];
          bxHeaders.forEach((element:any) => {
            const singleMappedData:any = [];
            const valuesToCheck:any = element.columnSynonyms
              ?[...element.columnSynonyms,element.columnName]
              :[element.columnName];
            const mappedArray:any = [];
            csvHeaders.forEach((csvObj:any) => {
              if(valuesToCheck.length&&valuesToCheck.map((item:any) => item.toLowerCase().trim()).indexOf(csvObj.columnName.toLowerCase().trim()) !==-1){
                mappedArray.push(csvObj);
              }
            });
            if(mappedArray.length){
              const indexToRemove:any = csvHeaders.findIndex((headerCheck:any) =>headerCheck.rowIndex ==mappedArray[0].rowIndex);
              indexToRemove!==-1 && (csvHeaders.splice(indexToRemove,1));
            }
            singleMappedData.push(element,mappedArray.length
              ?mappedArray[0]
              :{columnName:'Select'}); 
            prepareMappedData.push(singleMappedData);
          });
          this.mappedData = prepareMappedData;
          const mappedHeaders:any = [];
          this.mappedData.forEach((item:any) => {
            if(item[1].columnName!=='Select'){
              mappedHeaders.push(item[1].rowIndex);
            }
          });
          const newCsvHeaders:any = [];
          csvHeaders.forEach((item:any) => {
            if(mappedHeaders.indexOf(item.rowIndex)==-1){
              newCsvHeaders.push(item);
            }
          });
          const indexFinder:any = newCsvHeaders.filter((item:any) => item.columnName=='Select');
          /* istanbul ignore else */
          if(indexFinder.length != 1){
            newCsvHeaders.unshift({columnName:'Select'});
          }
          this.unmappedCsvHeaders = newCsvHeaders;
          const unresolvedRowIndexes:any = [];
          this.mappedData.forEach((item:any) => {
            if(item[1]&&(item[1].columnName=='Select')){
              unresolvedRowIndexes.push(item[0].rowIndex);
            }
          }); 
          this.unresolvedRowIndexes = unresolvedRowIndexes;
          this.dataFetched = true;
          /* istanbul ignore else */
          if(this.unresolvedRowIndexes.length>0) {
            this.validity = true;
            this.mappedData.forEach((item:any) => {
              if(this.unresolvedRowIndexes.indexOf(item[0].rowIndex)!==-1 && !item.isCustom){
                if(this.mandatoryHeaders.indexOf(item[0].columnName)!==-1){
                  this.validity = false;
                }
              }
            });
          }
          /* istanbul ignore else */
          if (this.mappedData && this.mappedData.length > 0 && this.mappedData[17].length > 0 && this.mappedData[17][0]['columnName'] == 'Country') {
            this.mappedData[17][0].groupName = 'Address';
          }
          this.wrapHeaderLabels();

          if (this.stepId < 1) {
            this.saveAutoRoaster();
            setTimeout(() => {
              this.getAutoSave(siteId, programId);
            },300);
            this.dataFetched = true;
          }
          else {
            this.getAutoSave(siteId, programId);
          }
        }
      }).catch((error:any)=>{
      });
    }

    obtainReqHeaders() {
      staff.bxrequiredStaffHeaders().then((response: any) => {
        /* istanbul ignore else */
        if (response.status === APP_CONST.RESPONSE_200) {
          const mandatoryHeaders = response.data.headerNames;
          this.obtainHeaders(mandatoryHeaders);
        }
      });
    }

    fillUnMappedList(obj:any){
      if(obj.rowIndex){
        const indexFind:any = this.unmappedCsvHeaders.findIndex((item:any) => item.rowIndex == obj.rowIndex);
        if(indexFind==-1){
          if(obj.maxCustReached){
            this.maxCustReached = false;
          }
          delete obj.maxCustReached;
          this.unmappedCsvHeaders.push(obj);
        }
      }
      else if(!obj.rowIndex && obj.maxCustReached){
        if(obj.maxCustReached){
          this.maxCustReached = false;
        }
        delete obj.maxCustReached;
      }
      else{
        if(obj.value.columnName == 'Select' && obj.label.columnName == 'Select'){
          return;
        }
        else if(obj.value.columnName == 'Select'&& obj.label.columnName !== 'Select'){
          /* istanbul ignore else */
          if(obj.label.rowIndex){
            this.mappedData.forEach((mapData:any) => {
              if(mapData[1].rowIndex&&(mapData[0].rowIndex==obj.headerRowIndex)){
                mapData[1] = {columnName:'Select'};
                mapData[1].isManuallyMapped = false;
                if(mapData[0].isCustom&&mapData[1].columnName!=='Select'){
                  if(mapData[0].isCustomNewlyCreated){
                    mapData[0].columnName = mapData[1].columnName;
                  }
                }
                else if(mapData[0].isCustom&&mapData[1].columnName=='Select'){
                  if(mapData[0].isCustomNewlyCreated){
                    mapData[0].columnName = 'Custom';
                  }
                }
                const checkInUnmapped:any= this.unmappedCsvHeaders.findIndex((header:any) => header.rowIndex==obj.label.rowIndex);
                if(checkInUnmapped==-1|| this.unmappedCsvHeaders.length==0){
                  this.unmappedCsvHeaders.push(obj.label);
                }
              }
            });
          }
        }
        else {
          if(obj.label.rowIndex&& obj.value.rowIndex){
            this.mappedData.forEach((mapData:any) => {
              if(mapData[1].rowIndex&&(mapData[0].rowIndex==obj.headerRowIndex)){
                mapData[1] = obj.value;
                mapData[1].isManuallyMapped = true;
                const checkInUnmapped:any= this.unmappedCsvHeaders.findIndex((header:any) => header.rowIndex==obj.label.rowIndex);
                if(checkInUnmapped==-1){
                  this.unmappedCsvHeaders.push(obj.label);
                  const removeUnMappedOneIndex:any =this.unmappedCsvHeaders.findIndex((headerCheck:any) => headerCheck.rowIndex==obj.value.rowIndex);
                  this.unmappedCsvHeaders.splice(removeUnMappedOneIndex,1);
                }
                if(mapData[0].isCustom&&mapData[1].columnName!=='Select'){
                  if(mapData[0].isCustomNewlyCreated){
                    mapData[0].columnName = mapData[1].columnName;
                  }
                }
                else if(mapData[0].isCustom&&mapData[1].columnName=='Select'){
                  if(mapData[0].isCustomNewlyCreated){
                    mapData[0].columnName = 'Custom';
                  }
                }
              }
            });
          }
          else if(!obj.label.rowIndex){
            this.mappedData.forEach((mapData:any) => {
              if(mapData[0].rowIndex==obj.headerRowIndex){
                mapData[1] = obj.value;
                mapData[1].isManuallyMapped = true;
                const removeUnMappedOneIndex:any =this.unmappedCsvHeaders.findIndex((headerCheck:any) => headerCheck.rowIndex==obj.value.rowIndex);
                this.unmappedCsvHeaders.splice(removeUnMappedOneIndex,1);
                if(mapData[0].isCustom&& mapData[1].columnName!=='Select'){
                  if(mapData[0].isCustomNewlyCreated){
                    mapData[0].columnName = mapData[1].columnName;
                  }
                }
                else if(mapData[0].isCustom&&mapData[1].columnName=='Select'){
                  if(mapData[0].isCustomNewlyCreated){
                    mapData[0].columnName ='Custom';
                  }
                }
              }
            });
          }
        }
      }
   
      const unresolvedRowIndexes:any = [];
      this.mappedData.forEach((item:any) => {
        if(item[1].columnName=='Select'){
          unresolvedRowIndexes.push(item[0].rowIndex);
        }
      }); 
      this.unresolvedRowIndexes = unresolvedRowIndexes;
      /* istanbul ignore else */
      if(this.unresolvedRowIndexes.length>0) {
        this.validity = true;
        this.mappedData.forEach((item:any) => {
          if(this.unresolvedRowIndexes.indexOf(item[0].rowIndex)!==-1 && !item.isCustom){
            if(this.mandatoryHeaders.indexOf(item[0].columnName)!==-1){
              this.validity = false;
            }
          }
        });
      }
      if(this.unresolvedRowIndexes.length==0){
        this.validity = true;
      }
    }

    handleScroll(event: any) {
      if (window.scrollY>=395) {
        this.stickyWrapperBtn = true;
      }
      else {
        this.stickyWrapperBtn = false;
      }
    }

    prevLabel() {
      if(this.currentUnresolved) {
        if(this.currentUnresolved!==0&&this.currentUnresolved<=this.unresolvedRowIndexes.length){
          this.currentUnresolved = this.currentUnresolved-1;
          this.navigateToLabel(this.unresolvedRowIndexes[this.currentUnresolved-1]);
        }
        if(this.currentUnresolved==0){
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }        
    }

    nextLabel() {
      if(this.currentUnresolved<=this.unresolvedRowIndexes.length-1){
        this.currentUnresolved = this.currentUnresolved+1;
        this.navigateToLabel(this.unresolvedRowIndexes[this.currentUnresolved-1]);
      }      
    }

    navigateToLabel(focusedLabel: string) {
      const element = document.getElementById(focusedLabel) as HTMLElement;
      if(element) {
        window.scrollBy({
          top: element.getBoundingClientRect().top -APP_CONST.KEY_210,
          behavior: 'smooth'
        });
      }
    }
    
    saveAutoRoaster() {
      const {siteId, programId} = APP_UTILITIES.coreids();
      const configureObj: {
            'programId': number;
            'siteId': number;
            'rosterStep': number;
            'step1': boolean;
            'step2': string;
            'step3': string;
            'visibleFileName': string;
            'actualFileName': string;
            'status': number;
        } = {
          'programId': programId,
          'siteId': siteId,
          'rosterStep': 2,
          'step1': true,
          'step2': '',
          'step3': '',
          'visibleFileName': '',
          'actualFileName': '',
          'status': 1
        };
      const step2ArrayFill:any = [];

      this.mappedData.forEach((item:any) => {
        const obj:any ={
          bxLabel:item[0].columnName,
          csvLabel: item[1].columnName,
          isCustom: item[0].isCustom
            ? true
            :false,
          isManuallyMapped: item[1].isManuallyMapped
            ?true
            :false,
          rowIndex: item[0].rowIndex,
          csvRowIndex:item[1].rowIndex
            ?item[1].rowIndex
            :''
        };
        step2ArrayFill.push(obj);          
      });
      configureObj.step2 = JSON.stringify(step2ArrayFill);
      this.getGlobalState.autoSaveStep2Data(configureObj.step2);
      staff.autoSaveStaffRoster(configureObj).then((response: any) => {
        this.updateUnresolvedCount();
      });
    }
 
    updateUnresolvedCount(){
      const unresolvedRowIndexes:any = [];
      this.mappedData.forEach((item:any) => {
        if(item[1]&&(item[1].columnName=='Select')){
          unresolvedRowIndexes.push(item[0].rowIndex);
        }
      }); 
      this.unresolvedRowIndexes = unresolvedRowIndexes;
      this.dataFetched = true;
      /* istanbul ignore else */
      if(this.unresolvedRowIndexes.length>0) {
        this.validity = true;
        this.mappedData.forEach((item:any) => {
          if(this.unresolvedRowIndexes.indexOf(item[0].rowIndex)!==-1 && !item.isCustom){
            if(this.mandatoryHeaders.indexOf(item[0].columnName)!==-1){
              this.validity = false;
            }
          }
        });
      }
    }
    getAutoSave(siteId: number, programId:number) {
      staff.getStaffAutoSaveData({ siteId, programId, stepNumber: 2 }).then((autoSaveRes: any) => {
        /* istanbul ignore else */
        if (autoSaveRes.status === APP_CONST.RESPONSE_200) {
          const step2Data = JSON.parse(autoSaveRes.data.step2);
          const bxHeaders:any = JSON.parse(JSON.stringify(this.bxHeaders));
          const csvHeaders:any = JSON.parse(JSON.stringify(this.csvHeaders));
          const prepareMappedData:any = [];
                

          bxHeaders.forEach((element:any) => {
            const singleMappedData:any = [];
            const mappedIndex:any = step2Data.findIndex((item:any) => item.rowIndex == element.rowIndex);

            if(mappedIndex!==-1){
              const isManuallyMapped:any = step2Data[mappedIndex].isManuallyMapped;
              const csvIndex:any = csvHeaders.findIndex((item:any) => step2Data[mappedIndex].csvRowIndex == item.rowIndex);
              if(csvIndex!==-1){
                const objectForm:any = {...csvHeaders[csvIndex],isManuallyMapped:csvHeaders[csvIndex].columnName=='Select'
                  ?false
                  :isManuallyMapped};
                singleMappedData.push(element,objectForm);
                csvHeaders.splice(csvIndex,1);
              }
              else{
                singleMappedData.push(element,{columnName:'Select',isManuallyMapped:false});
              }
            } 
            prepareMappedData.push(singleMappedData);
          });
          this.mappedData = prepareMappedData.filter((item:any) => item.length!==0);
             
          const mappedHeaders:any = [];
          step2Data.forEach((item:any) => {
            if(item.csvRowIndex&&item.csvRowIndex!==''){
              mappedHeaders.push(item.csvRowIndex);
            }
          });
          const newCsvHeaders:any = [];
          this.csvHeaders.forEach((item:any) => {
            if(mappedHeaders.indexOf(item.rowIndex)==-1){   
              newCsvHeaders.push(item);
            }
          });
          const indexFinder:any = newCsvHeaders.filter((item:any) => item.columnName=='Select');
          /* istanbul ignore else */
          if(indexFinder.length != 1){
            newCsvHeaders.unshift({columnName:'Select'});
          }
          this.unmappedCsvHeaders = newCsvHeaders;
          const unresolvedRowIndexes:any = [];
          this.mappedData.forEach((item:any) => {
            if(item[1]&&(item[1].columnName=='Select')){
              unresolvedRowIndexes.push(item[0].rowIndex);
            }
          }); 
          this.unresolvedRowIndexes = unresolvedRowIndexes;
          this.dataFetched = true;
          /* istanbul ignore else */
          if(this.unresolvedRowIndexes.length>0) {

            this.validity = true;
            this.mappedData.forEach((item:any) => {
              if(this.unresolvedRowIndexes.indexOf(item[0].rowIndex)!==-1 && !item.isCustom){
                if(this.mandatoryHeaders.indexOf(item[0].columnName)!==-1){
                  this.validity = false;
                }
              }
            });
          }
            
        }
      });
    }


    openTooltip() {
      const toolTipObject: any = document.getElementById(APP_CONST.TOOL_TIP);
      if(toolTipObject){
        /* istanbul ignore else */
        if (!toolTipObject.style.visibility || (toolTipObject.style.visibility === APP_CONST.HIDDEN)) {
          toolTipObject.style.visibility = APP_CONST.VISIBLE;
        }
      }
        
    }

    closeTooltip() {
      const toolTipObject: any = document.getElementById(APP_CONST.TOOL_TIP);
      if(toolTipObject){
        toolTipObject.style.visibility = APP_CONST.HIDDEN;
      }
        
    }

    wrapHeaderLabels() {
      setTimeout(() => {            
        const classesFinder:any = ['.Staffinfo'];
        const headingTagger:any = ['Staff Info'];
        classesFinder.forEach((itemNew:any,indexUp:any) => {
          let addedToDocument = false;
          const wrapper = document.createElement('div');
          wrapper.id = 'form-row-wrapper';
          const nodesToWrap = [...document.querySelectorAll(itemNew)];
          const headingTag:any =  document.createElement('h3');
          headingTag.textContent = headingTagger[indexUp];
          wrapper.appendChild(headingTag);
          for (let index = 0; index <=nodesToWrap.length; index++) {
            const node:any = nodesToWrap[index];
            /* istanbul ignore else */
            if (!addedToDocument) {
              node&&node.parentNode&&(node.parentNode.insertBefore(wrapper, node));
              node&&node.parentNode&&(addedToDocument = true);
            }
            node&&node.parentNode&&(node.parentNode.removeChild(node));
            node&&wrapper.appendChild(node);  
          }
        });
      },100); 
    }

    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    }

}