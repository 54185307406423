import { Vue, Component, Emit } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import APP_UTILITIES from '@/utilities/commonFunctions';
import API_CONST from '@/constants/ApiConst';
import APP_CONST from '@/constants/AppConst';
import { staffStatus, deleteRosterAPI } from '@/services/staff/staffService';

@Component({
  components: {
    vueDropzone: vue2Dropzone
  }
})

export default class UploadFileComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
   
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    @Emit('next') nextScreen(mode: any) {}

    public maxFilesize: number = 5000000;
    public totalFilesize: number = 0;
    public maxFileSizeFlag: boolean = false;
    public isCsvFileUploaded: boolean = false;
    public isFileSelected: boolean = false;
    public isFileCorrect: boolean = true;
    public currentCsvFileName: string = '';
    public userRoles : any = [];
    public siteId : number = 0;
    public disableDelete: boolean = false;
    public dropzoneOptions: any = {
      method: 'POST',
      url: `${process.env.VUE_APP_BASE_IDENTITY_URL}${API_CONST.UPLOAD_CSV_STAFF}?accountId=`+APP_UTILITIES.getCookie('accountId'),
      maxFilesize: 5,
      maxFiles: 1,
      headers: {
        'Accept' : '*/*',
        'Authorization': `Bearer ${APP_UTILITIES.getCookie('auth_token')}`
      },
      includeStyling: false,
      chunkSize: 500,
      addRemoveLinks: true,
      acceptedFiles: '.csv,text/csv',
      clickable: true,
      timeout: 180000,
    };

    mounted(){
      const siteId = this.getSiteId();
      const programId = this.getProgramId();
      const accountId = APP_UTILITIES.getCookie('accountId');
      staffStatus(accountId).then((response : any) => {
        /* istanbul ignore else */
        if(response.status === 200){
          this.currentCsvFileName = response.data.actualFileName;
          this.maxFileSizeFlag = true;
          this.isFileCorrect = true;
          this.isCsvFileUploaded = true;
          this.isFileSelected = false;
        }
      });
    }

    public getSiteId() {
      let sID = 0;
      let roleId = 0;
      const userRoles: any = APP_UTILITIES.getCookie('user_role');
      this.userRoles = JSON.parse(userRoles);
      this.userRoles.forEach((item: any) => {
        /* istanbul ignore else */
        if (item.hasOwnProperty('roleId')) {
          roleId = item.roleId;
        }
      });
      if (roleId === APP_CONST.ROLE_TYPE_PROGRAM_ADMIN) {
        return sID;
      }
      else {
        this.userRoles.forEach((item: any) => {
          /* istanbul ignore else */
          if (item.hasOwnProperty('siteId')) {
            sID = item.siteId;
          }
        });
        return this.siteId = sID;
      }
    }

    getProgramId() {
      let programId = 0;
      const userRoles: any = APP_UTILITIES.getCookie('user_role');
      this.userRoles = JSON.parse(userRoles);
      this.userRoles.forEach((item: any) => {
        /* istanbul ignore else */
        if (item.hasOwnProperty('programId')) {
          programId = item.programId;
        }
      });
      return programId;
    }

    fileAdded(file: any) {
      this.isCsvFileUploaded = false;
      this.isFileSelected = true;
      this.isFileCorrect = true;
      this.maxFileSizeFlag = true;
      this.clearCurrentCSVFile();
    }

    sendingFiles(files: any, xhr: any, formData: any) {
      this.totalFilesize = 0;
      this.totalFilesize = this.totalFilesize + files.size;
      if (this.totalFilesize <= this.maxFilesize) {
        this.maxFileSizeFlag = true;
      }
      else {
        this.maxFileSizeFlag = false;
        this.removeAllFiles();
      }
    }

    uploadProgress(file: any, progress: any, bytesSent: any) {
    }

    success(file: any, response: any) {
      this.isCsvFileUploaded = true;
      this.isFileSelected = false;
      this.currentCsvFileName = file.name;
      this.removeAllFiles();
    }

    onError(file: any, response: any) {
      if(response === this.getScreenText('DZ_ERROR_FILE_TYPE')){
        this.isFileCorrect = false;
        this.clearCurrentCSVFile();
        this.removeAllFiles();
      }
      else {
        this.maxFileSizeFlag = false;
        this.removeAllFiles();
        this.isFileCorrect = false;
        this.clearCurrentCSVFile();
      }
    }

    removeAllFiles() {
      const csvInstance: any = this.$refs.myVueDropzone;
      csvInstance.removeAllFiles(true);
    }

    removeCurrentSelection(e:any) {
      this.disableDelete = true;
      const csvInstance: any = this.$refs.myVueDropzone;
      csvInstance.removeEventListeners();
      const siteId = this.getSiteId();
      const programId = this.getProgramId();
      deleteRosterAPI(siteId).then((response : any) => {
        /* istanbul ignore else */
        if(response.status === 200){
          csvInstance.setupEventListeners();
          csvInstance.removeAllFiles(true);
          this.clearCurrentCSVFile();
          this.isCsvFileUploaded = false;
          this.isFileSelected = false;
          this.maxFileSizeFlag = false;
          this.isFileCorrect = true;
        }
        this.disableDelete = false;
      });
    }

    public clearCurrentCSVFile(){
      this.currentCsvFileName = '';
    }

    public uploadCSV(){
      this.nextScreen('match-columns');
    }
}