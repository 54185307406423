import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export class UnresolvedAlertComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    continueChecker:any = false;

    @Prop()
    continue_or_proceed! : string;

    @Prop({default: ''})
    page_title! : string;

    @Prop({default: ''})
    theme!: string;

   
   
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    moveToNext() {
      this.$emit('next');
    }

    closePopup() {
      this.$emit(APP_CONST.CLOSE);
    }

   

}